.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programs-header {
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}

.program-categories{
    display: flex;
    gap:1rem;
}

.category{
    display: flex;
    flex-direction: column;
    background-color: grey;
    padding: 2rem;
    gap: 1rem;
    color: white;
    justify-content: space-between;
}

.category>:nth-child(1)
{
    width: 2rem;
    height: 2rem; fill: white;
}

.category>:nth-child(2)
{
   font-size: 1rem;
   font-weight: bold;
}
.category>:nth-child(3)
{
   font-size: 0.9rem;
   line-height: 25px;
}

.join-now{
    display: flex;
    align-items:center;
    gap: 2rem;

}

.join-now>img{
    width: 1rem;
}

.category:hover{
    background: var(--planCard);
    cursor: pointer;
}

@media screen  and (max-width :768px){
    .programs-header{
     flex-direction: column;
     gap: 1rem;   
     font-size: x-large;
     align-items: center;
     justify-content: center;
     margin-top: 2rem;
    }

    .program-categories{
        flex-direction: column;

    }
}